import { createWebHistory, createRouter } from "vue-router";
import AuthGuard from "./utils/auth.guard";
import { adminRoot } from "./constant/config";

const routes = [
  {
    path: "/",
    component: () => import("./views/index.vue"),
    redirect: `${adminRoot}/dashboard`,
  },
  {
    path: "/register",
    name: "register",
    component: () => import("./views/Register.vue"),
  },
  {
    path: `/auth`,
    component: () => import("./views"),
    redirect: `/auth/login`,
    children: [
      {
        path: "login",
        name: "login",
        component: () => import("./views/auth/Login.vue"),
      },
      {
        path: "logout",
        name: "logout",
        component: () => import("./views/auth/Logout.vue"),
      },
    ],
  },
  {
    path: `${adminRoot}`,
    component: () => import("./views"),
    redirect: `${adminRoot}/dashboard`,
    meta: { loginRequired: true },
    children: [
      {
        path: "/fillup",
        name: "fillup",
        component: () => import("./views/FillUp.vue"),
      },
      {
        path: "dashboard",
        component: () => import("./views/dashboard/Dashboard.vue"),
      },
      {
        path: "profile",
        name: "profile",
        component: () => import("./views/user/Profile.vue"),
      },
      {
        path: "redeemAdmin",
        name: "redeem.admin",
        meta: { roles: ["ROOT"] },
        component: () => import("./views/redeem/RedeemListAdmin.vue"),
      },
      {
        path: "redeem",
        name: "redeem",
        component: () => import("./views/redeem/RedeemList.vue"),
      },
      
      {
        path: "redeem/add",
        name: "redeem.addRedeem",
        component: () => import("./views/redeem/RedeemAdd.vue"),
      },
      {
        path: "approveHotel",
        name: "approve.hotel",
        meta: { roles: ["ROOT"] },
        component: () => import("./views/user/Approve.vue"),
      },
      {
        path: "approveHotel/:userId",
        name: "approve.detailHotel",
        meta: { roles: ["ROOT"] },
        component: () => import("./views/user/ApproveDetail.vue"),
      },
      {
        path: "package",
        component: () => import("./views/package/PackageAddEdit.vue"),
      },
      {
        path: `${adminRoot}/setting`,
        component: () => import("./views"),
        redirect: `${adminRoot}/setting/main`,
        children: [
          {
            path: "main",
            name: "setting.main",
            component: () => import("./views/setting/Setting.vue"),
          },
        ],
      },
      {
        path: `${adminRoot}/campaignxxx`,
        component: () => import("./views"),
        redirect: `${adminRoot}/campaign/main`,
        children: [
          {
            path: "main",
            name: "campaign.list",
            component: () => import("./views/campaign/CampaignList.vue"),
          },
          {
            path: "new",
            name: "campaign.new",
            component: () => import("./views/campaign/CampaignAddEdit.vue"),
          },
          {
            path: ":campaignId",
            name: "campaign.new",
            component: () => import("./views/campaign/CampaignAddEdit.vue"),
          },
          {
            path: ":campaignId/log",
            name: "campaign.log",
            component: () => import("./views/campaign/CampaignLog.vue"),
          },
        ],
      },
    ],
  },
  {
    path: "/:catchAll(.*)",
    component: () => import("./views/Pagenotfound.vue"),
    name: "pagenotfound",
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(AuthGuard);
export default router;
