import { authAxios } from "../../plugins/axios";
export default {
  namespaced: true,
  state: {
    redeem: [],
    dataList: [],
  },
  getters: {
    getRedeem: (state) => state.redeem,
    getDataList: (state) => state.dataList,
  },
  mutations: {
    setRedeem(state, payload) {
      state.redeem = payload;
    },
    setDataList(state, payload) {
      state.dataList = payload;
    },
  },
  actions: {
    async fetchRedeemList({ commit }, payload) {
      let query = "";
      if (payload.page) {
        query = `?${new URLSearchParams({
          ...payload,
        })}`;
      }
      const result = await authAxios.get(`/backoffice/v1/redeem/${query}`);
      if (result) {
        commit(
          "setDataList",
          result.data.data.map((obj) => {
            return {
              ...obj,
              name: obj.guests && obj.guests[0] ? obj.guests[0].firstname : "-",
            };
          })
        );
        return result.data;
      } else {
        throw "something went wrong";
      }
    },
    async createRedeem(_, payload) {
      try {
        const result = await authAxios.post("/backoffice/v1/redeem", payload);
        return result;
      } catch (error) {
        throw error;
      }
    },
    async fetchRedeemListAdmin({ commit }, payload) {
      let query = "";
      if (payload.page) {
        query = `?${new URLSearchParams({
          ...payload,
        })}`;
      }
      const result = await authAxios.get(`/backoffice/v1/redeem/${query}`);
      if (result) {
        commit(
          "setDataList",
          result.data.data.map((obj) => {
            return {
              ...obj,
              name:
                (obj.guests && obj.guests[0] && obj.guests[0].firstname) || "-",
              hotelName: (obj.hotelId && obj.hotelId.hotelName) || "-",
              shaCode: (obj.hotelId && obj.hotelId.shaCode) || "-",
              phoneNumber: (obj.hotelId && obj.hotelId.phoneNumber) || "-",
            };
          })
        );
        return result.data;
      } else {
        throw "something went wrong";
      }
    },
    async approve(_, payload) {
      try {
        const result = await authAxios.post(
          `/backoffice/v1/redeem/${payload}/approve`,
          {}
        );
        return result;
      } catch (error) {
        throw error;
      }
    },
    async reject(_, payload) {
      try {
        const result = await authAxios.post(
          `/backoffice/v1/redeem/${payload}/reject`,
          {}
        );
        return result;
      } catch (error) {
        throw error;
      }
    },
    async updateRedeem(_, payload) {
      try {
        const result = await authAxios.patch(
          `/backoffice/v1/redeem/${payload.packageId}`,
          payload
        );

        return result;
      } catch (error) {
        throw error;
      }
    },
  },
};
