<template>
  <div>
    <div class="trigger" @click="toggleVisible">
      <MenuFoldOutlined v-if="isVisible" />
      <MenuUnfoldOutlined v-if="!isVisible" />
    </div>
    <a-drawer
      title="Social's Beacon"
      placement="left"
      :closable="true"
      :visible="isVisible"
      @close="toggleVisible"
    >
      <Menu />
    </a-drawer>
  </div>
</template>

<script>
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons-vue";
import { mapGetters, useStore } from "vuex";
import { useRoute } from "vue-router";
import { computed, ref, watch } from "vue";
import Menu from "../components/Menu";
export default {
  components: {
    Menu,
    MenuFoldOutlined,
    MenuUnfoldOutlined,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const isVisible = ref();

    const toggleVisible = () => {
      console.log("toggle", !isVisible.value);
      isVisible.value = !isVisible.value;
    };

    watch(
      () => route,
      (to, from) => {
        isVisible.value = false;
      }
    );
    return {
      openKeys: [`${route.path.split("/").filter((x) => x !== "")[1]}`],
      isVisible,
      toggleVisible,
    };
  },
};
</script>
