import { authAxios } from "../../plugins/axios";
export default {
  namespaced: true,
  state: {
    data: [],
    logs: [],
  },
  getters: {
    getData: (state) => state.data,
    getLogs: (state) => state.logs,
  },
  mutations: {
    setData(state, payload) {
      state.data = payload;
    },
    setLogs(state, payload) {
      state.logs = payload;
    },
    deleteData(state, payload) {
      state.data = [...state.data].filter((data) => data.id != payload.id);
    },
  },
  actions: {
    async fetchCampaign({ commit }, payload) {
      let query = "";
      if (payload.page) {
        query = `?${new URLSearchParams({
          ...payload,
        })}`;
      }
      const result = await authAxios.get(`/backoffice/v1/campaign${query}`);
      if (result) {
        commit("setData", [...result.data.data]);
        return result.data;
      } else {
        throw "something went wrong";
      }
    },

    async getCampaign({ commit }, payload) {
      const result = await authAxios.get(
        `/backoffice/v1/campaign/${payload.id}`
      );
      if (result) {
        return result.data;
      } else {
        throw "something went wrong";
      }
    },

    async newCampaign({ commit }, payload) {
      const result = await authAxios.post(`/backoffice/v1/campaign`, payload);
      if (result) {
        return result.data;
      } else {
        throw "something went wrong";
      }
    },

    async updateCampaign({ commit }, payload) {
      const result = await authAxios.patch(`/backoffice/v1/campaign`, payload);
      if (result) {
        return result.data;
      } else {
        throw "something went wrong";
      }
    },

    async deleteCampaign({ commit }, payload) {
      const result = await authAxios.delete(
        `/backoffice/v1/campaign/${payload.id}`
      );
      if (result) {
        commit("deleteData", payload);
        return result.data;
      } else {
        throw "something went wrong";
      }
    },

    async fetchCampaignLog({ commit }, payload) {
      let query = "";
      if (payload.page) {
        query = `?${new URLSearchParams({
          ...payload,
        })}`;
      }
      const result = await authAxios.get(
        `/backoffice/v1/campaign/${payload.campaignId}/log${query}`
      );
      if (result) {
        commit("setLogs", [...result.data.data]);
        return result.data;
      } else {
        throw "something went wrong";
      }
    },
  },
};
