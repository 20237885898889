import Vuex from "vuex";

// import app from "../main";
import auth from "./modules/auth";
import campaign from "./modules/campaign";
import bucket from "./modules/bucket";
import zone from "./modules/zone";
import packages from "./modules/package";
import user from "./modules/user";
import redeem from "./modules/redeem";
import setting from "./modules/setting";

export default new Vuex.Store({
  state: {
    // locale: app.$i18n.locale,
  },
  mutations: {
    changeLang(state, payload) {
      // app.$i18n.locale = payload;
      setCurrentLanguage(payload);
    },
  },
  actions: {
    setLang({ commit }, payload) {
      commit("changeLang", payload);
    },
  },
  modules: {
    auth,
    bucket,
    campaign,
    packages,
    redeem,
    setting,
    user,
    zone,
  },
});
