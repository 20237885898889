import { authAxios } from "../../plugins/axios";

//template action
export default {
  namespaced: true,
  state: {
    data: [],
  },
  getters: {},
  mutations: {},
  actions: {
    uploadLink(_, payload) {
      return authAxios.post(
        `/backoffice/v1/bucket/${payload.bucketName}/uploadLink`,
        {
          contentType: payload.contentType,
        }
      );
    },
    deleteImage(_, payload) {
      return authAxios.delete(
        `/backoffice/v1/bucket/${payload.bucketName}/${payload.fileName}`
      );
    },
    getPrivateUrl(_, payload) {
      return authAxios.get(
        `/backoffice/v1/bucket/${payload.bucketName}/${payload.fileName}`
      );
    },
  },
};
