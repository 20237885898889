import { authAxios } from "../../plugins/axios";

//package action
export default {
  namespaced: true,
  state: {
    data: [],
  },
  getters: {
    getData: (state) => state.data,
  },
  mutations: {
    setData(state, payload) {
      state.data = payload;
    },
    addData(state, payload) {
      state.data = [...state.data, payload];
    },
    updateData(state, payload) {
      const tmp = state.data;
      const idx = tmp.findIndex((x) => x.id == payload.id);
      tmp[idx] = { ...state.data[idx], ...payload };
      state.data = tmp;
    },
    deleteData(state, payload) {
      state.data = [...state.data].filter((data) => data.id != payload.id);
    },
  },
  actions: {
    async fetchPackage({ commit }, payload) {
      let query = "";
      if (payload.page) {
        query = `?${new URLSearchParams({
          ...payload,
        })}`;
      }
      const result = await authAxios.get(`/backoffice/v1/package${query}`);
      if (result) {
        commit("setData", [...result.data.data]);
        return result.data;
      } else {
        throw "something went wrong";
      }
    },

    async getPackage({ commit }, payload) {
      const result = await authAxios.get(
        `/backoffice/v1/package/${payload.id}`
      );
      if (result) {
        return result.data;
      } else {
        throw "something went wrong";
      }
    },

    async newPackage({ commit }, payload) {
      const result = await authAxios.post(`/backoffice/v1/package`, payload);
      if (result) {
        return result.data;
      } else {
        throw "something went wrong";
      }
    },

    async updatePackage({ commit }, payload) {
      const result = await authAxios.patch(
        `/backoffice/v1/package/${payload.id}`,
        payload
      );
      if (result) {
        return result.data;
      } else {
        throw "something went wrong";
      }
    },

    async deletePackage({ commit }, payload) {
      const result = await authAxios.delete(
        `/backoffice/v1/package/${payload.id}`
      );
      if (result) {
        commit("deleteData", payload);
        return result.data;
      } else {
        throw "something went wrong";
      }
    },
  },
};
