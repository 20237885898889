<template>
  <div class="side-menu-wrapper">
    <Menu />
  </div>
</template>
<script>
import { defineComponent, reactive, toRefs } from "vue";
import Menu from "../components/Menu";
export default defineComponent({
  components: {
    Menu,
  },
  setup() {
    const state = reactive({});

    return { ...toRefs(state) };
  },
});
</script>

<style scoped>
.side-menu-wrapper {
  flex: auto;
  min-width: 0;
  background: #fff;
}
</style>
