<template>
  <a-menu
    mode="inline"
    :selectedKeys="[`${selectedKeys}`]"
    :openKeys="[`${openKeys}`]"
  >
    <a-menu-item key="office-dashboard">
      <template #icon>
        <HomeOutlined />
      </template>
      <router-link :to="`${adminRoot}/dashboard`">
        <span>Home</span>
      </router-link>
    </a-menu-item>

    <div v-if="userRole === 'ROOT'">
      <a-menu-item key="office-logout">
        <template #icon>
          <AuditOutlined />
        </template>
        <router-link :to="{ name: 'approve.hotel' }">
          <span>Approve Hotel</span>
        </router-link>
      </a-menu-item>
    </div>

    <a-menu-item key="package" v-if="userRole === 'ROOT'">
      <template #icon>
        <UnorderedListOutlined />
      </template>
      <router-link :to="`${adminRoot}/setting`">
        <span>Setting</span>
      </router-link>
    </a-menu-item>

    <a-menu-item key="package" v-if="userRole === 'ROOT'">
      <template #icon>
        <GiftOutlined />
      </template>
      <router-link :to="`${adminRoot}/redeemAdmin`">
        <span>Redeems</span>
      </router-link>
    </a-menu-item>

    <div v-else>
      <a-menu-item key="office-logout">
        <template #icon>
          <UserOutlined />
        </template>
        <router-link :to="{ name: 'profile' }">
          <span>Profile</span>
        </router-link>
      </a-menu-item>

      <a-menu-item key="package">
        <template #icon>
          <UnorderedListOutlined />
        </template>
        <router-link :to="`${adminRoot}/package`">
          <span>Package</span>
        </router-link>
      </a-menu-item>

      <a-menu-item key="redeem">
        <template #icon>
          <GiftOutlined />
        </template>
        <router-link :to="`${adminRoot}/redeem`">
          <span>Redeem</span>
        </router-link>
      </a-menu-item>

      <a-sub-menu key="campaign" v-if="false">
        <template #icon>
          <NotificationOutlined />
        </template>
        <template #title>Campaign</template>
        <a-menu-item key="office-campaign-main">
          <template #icon>
            <UnorderedListOutlined />
          </template>
          <router-link :to="`${adminRoot}/campaign/main`">
            <span>List</span>
          </router-link>
        </a-menu-item>
        <a-menu-item key="office-campaign-log">
          <template #icon>
            <HistoryOutlined />
          </template>
          <router-link :to="`${adminRoot}/campaign/new`">
            <span>New</span>
          </router-link>
        </a-menu-item>
      </a-sub-menu>
    </div>

    <a-menu-item key="office-logout">
      <template #icon>
        <LogoutOutlined />
      </template>
      <router-link :to="`/auth/logout`">
        <span>Logout</span>
      </router-link>
    </a-menu-item>
  </a-menu>
</template>

<script>
import {
  TeamOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UserOutlined,
  HomeOutlined,
  LogoutOutlined,
  UnorderedListOutlined,
  HistoryOutlined,
  NotificationOutlined,
  AuditOutlined,
  GiftOutlined,
} from "@ant-design/icons-vue";
import { mapGetters } from "vuex";
import { adminRoot } from "../constant/config";
import { useRoute } from "vue-router";
import { computed, ref, watch, onMounted, reactive } from "vue";
import { useStore } from "vuex";
export default {
  components: {
    TeamOutlined,
    MenuFoldOutlined,
    MenuUnfoldOutlined,
    UserOutlined,
    HomeOutlined,
    LogoutOutlined,
    UnorderedListOutlined,
    HistoryOutlined,
    NotificationOutlined,
    AuditOutlined,
    GiftOutlined,
  },
  setup() {
    const route = useRoute();
    const store = useStore();
    const userRole = ref();

    const selectedKeys = ref(
      `${route.path
        .split("/")
        .filter((x) => x !== "")
        .join("-")}`
    );
    const openKeys = ref(`${route.path.split("/").filter((x) => x !== "")[1]}`);

    watch(
      () => route.path,
      (path) => {
        //key active by path
        // /office/dashboard >>> office-dashboard

        //open key
        ///office/dashboard  >>> dashboard
        openKeys.value = `${path.split("/").filter((x) => x !== "")[1]}`;

        selectedKeys.value = path
          .split("/")
          .filter((x) => x !== "")
          .join("-");
      }
    );

    onMounted(async () => {
      const user = await store.getters["auth/currentUser"];
      userRole.value = user.role;
    });

    return {
      openKeys,
      selectedKeys,
      adminRoot,
      userRole,
    };
  },
};
</script>
