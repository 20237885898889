import { authAxios } from "../../plugins/axios";
export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async getZoneList(_) {
      const result = await authAxios.get(`/api/v1/zone`);
      if (result) {
        return result.data;
      } else {
        throw "something went wrong";
      }
    },
  },
};
