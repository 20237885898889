import { authAxios } from "../../plugins/axios";

//template action
export default {
  namespaced: true,
  state: {
    remainingRedeem: 0,
    remainingAccepted: 0,
  },
  getters: {
    getRemainingRedeem: (state) => state.remainingRedeem,
    getRemainingAccepted: (state) => state.remainingAccepted,
  },
  mutations: {
    setRemaining(state, payload) {
      state.remainingRedeem = payload.remainingRedeem;
      state.remainingAccepted = payload.remainingAccepted;
    },
    setRemainingRedeem(state, payload) {
      state.remainingRedeem = payload;
    },
    addRemainingRedeem(state, payload) {
      state.remainingRedeem = state.remainingRedeem + payload;
    },
    removeRemainingRedeem(state, payload) {
      state.remainingRedeem = state.remainingRedeem - payload;
    },
    setRemainingAccepted(state, payload) {
      state.remainingRedeem = payload;
    },
    addRemainingAccepted(state, payload) {
      state.remainingAccepted = state.remainingAccepted + payload;
    },
    removeRemainingAccepted(state, payload) {
      state.remainingAccepted = state.remainingAccepted - payload;
    },
  },
  actions: {
    async refetchSetting({ commit }, payload) {
      try {
        const result = await authAxios.get(`/backoffice/v1/setting`);
        commit("setRemaining", result.data.data);
        return result.data;
      } catch (error) {
        throw error;
      }
    },
    async addQuota({ commit }, { qty }) {
      try {
        const result = await authAxios.post(`/backoffice/v1/setting/addQuota`, {
          qty,
        });
        commit("setRemainingRedeem", result.data.remainingRedeem);
        return result.data;
      } catch (error) {
        throw error;
      }
    },
    async removeQuota({ commit }, { qty }) {
      try {
        const result = await authAxios.post(
          `/backoffice/v1/setting/removeQuota`,
          {
            qty,
          }
        );
        commit("setRemainingRedeem", result.data.remainingRedeem);
        return result.data;
      } catch (error) {
        throw error;
      }
    },
  },
};
