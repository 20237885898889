import { authAxios } from "../../plugins/axios";
export default {
  namespaced: true,
  state: {
    user: [],
    dataList: [],
  },
  getters: {
    getUser: (state) => state.user,
    getDataList: (state) => state.dataList,
  },
  mutations: {
    setUser(state, payload) {
      state.user = payload;
    },
    setDataList(state, payload) {
      state.dataList = payload;
    },
  },
  actions: {
    async fetchUser({ commit }, payload) {
      let query = "";
      if (payload.page) {
        query = `?${new URLSearchParams({
          ...payload,
        })}`;
      }
      const result = await authAxios.get(`/backoffice/v1/user${query}`);
      if (result) {
        commit("setUser", [...result.data.data]);
        return result.data;
      }
    },
    async fetchUserList({ commit }, payload) {
      let query = "";
      if (payload.page) {
        query = `?${new URLSearchParams({
          ...payload,
        })}`;
      }
      const result = await authAxios.get(`/backoffice/v1/officer/${query}`);
      if (result) {
        commit("setDataList", [...result.data.data]);
        return result.data;
      } else {
        throw "something went wrong";
      }
    },
    async fetchUserDetail({ commit }, payload) {
      const result = await authAxios.get(`/backoffice/v1/officer/${payload}`);
      if (result) {
        return result.data;
      } else {
        throw "something went wrong";
      }
    },
    async fetchRefUserDetailByShaId({ commit }, payload) {
      const result = await authAxios.get(
        `/backoffice/v1/refOfficer/?shaId=${payload}`
      );
      if (result) {
        return result.data;
      } else {
        throw "something went wrong";
      }
    },
    async approve(_, payload) {
      const result = await authAxios.patch(
        `/backoffice/v1/officer/${payload}/approved`
      );
      if (result) {
        return result;
      } else {
        throw "error approved";
      }
    },
  },
};
