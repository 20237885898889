import { createApp } from "vue";
import App from "./App.vue";
import Antd from "ant-design-vue";
import router from "./router";
import store from "./store";
import OfficeLayout from "../src/layouts/OfficeLayout.vue";
import "ant-design-vue/dist/antd.css";
import VueApexCharts from "vue3-apexcharts";

const Vue = createApp(App);

Vue.use(router);
Vue.use(Antd);
Vue.use(store);

// The Vue.use(VueApexCharts) will make <apexchart> component available everywhere.
Vue.use(VueApexCharts);

Vue.component("office-layout", OfficeLayout);
Vue.mount("#app");
