import { authAxios } from "../../plugins/axios";
import { currentUser, isAuthGuardActive } from "../../constant/config";
import { setCurrentUser, getCurrentUser } from "../../utils";
export default {
  namespaced: true,
  state: {
    currentUser: isAuthGuardActive ? getCurrentUser() : currentUser,
  },
  getters: {
    currentUser: (state) => state.currentUser,
  },
  mutations: {
    setUser(state, payload) {
      state.currentUser = { profile: {}, ...state.currentUser, ...payload };
      state.processing = false;
      state.loginError = null;
    },
    setUserProfile(state, payload) {
      state.currentUser = {
        ...state.currentUser,
        profile: payload,
      };
      state.processing = false;
      state.loginError = null;
    },
    setLogout(state) {
      state.currentUser = null;
      state.processing = false;
      state.loginError = null;
    },
  },
  actions: {
    async login({ commit }, payload) {
      const result = await authAxios.post("/backoffice/v1/auth/login", payload);
      if (result) {
        const item = {
          username: payload.username,
          ...result.data,
        };
        setCurrentUser(item);
        commit("setUser", item);
        return result;
      } else {
        setCurrentUser(null);
        commit("setUser", {});
        throw "invalid credentials";
      }
    },
    async profile({ commit }) {
      const result = await authAxios.get("/backoffice/v1/auth/profile");
      if (result) {
        commit("setUserProfile", result.data);
      }
    },
    logout({ commit }) {
      setCurrentUser(null);
      commit("setLogout");
    },
    async register({ commit }, payload) {
      const result = await authAxios.post(
        "/backoffice/v1/auth/register",
        payload
      );
      if (result) {
        const item = {
          username: payload.phoneNumber,
          ...result.data,
        };
        setCurrentUser(item);
        commit("setUser", item);
        return result;
      } else {
        setCurrentUser(null);
        commit("setUser", {});
        throw "invalid credentials";
      }
    },
    async fillUp(_, payload) {
      const result = await authAxios.post(
        "/backoffice/v1/auth/fillup",
        payload
      );
      if (result) {
        return result;
      } else {
        throw "invalid fill up hotel information";
      }
    },
    async updateFillUp(_, payload) {
      const result = await authAxios.patch(
        "/backoffice/v1/auth/fillup",
        payload
      );
      if (result) {
        return result;
      } else {
        throw "invalid fill up hotel information";
      }
    },
    async updateProfile(_, payload) {
      const result = await authAxios.patch(
        "/backoffice/v1/auth/profile",
        payload
      );
      if (result) {
        return result;
      } else {
        throw "invalid profile information";
      }
    },
  },
};
