<template>
  <div>
    <router-view />
  </div>
</template>
<style></style>

<script>
import { mapGetters, mapActions } from "vuex";
import _ from "lodash";

export default {
  computed: {
    ...mapGetters({
      processing: "auth/processing",
      user: "auth/currentUser",
    }),
  },
  methods: {
    ...mapActions({
      logout: "auth/logout",
    }),
  },
};
</script>
