import { UserRole } from "../utils/auth.roles";
export const isAuthGuardActive = true;
export const defaultLocale = "en";

export const currentUser = {
  username: "skeletonadmin",
  token: "ยิงจริงไมไ่ด้นะ token อันนี้",
  refreshToken: "ยิงจริงไมไ่ด้นะ token อันนี้",
  role: UserRole.Admin,
};

export const adminRoot = "/office";
