<template>
  <a-layout id="office-layout" v-if="loaded">
    <a-layout-sider style="background: #fff" v-if="!isMobile">
      <div class="logo-wrapper">
        <router-link to="/">
          <div class="logo" />
        </router-link>
      </div>
      <side-menu></side-menu>
    </a-layout-sider>
    <a-layout
      :style="{
        padding: '0 0.05em',
        width: '100%',
        overflow: 'hidden',
        minHeight: '100vh',
      }"
    >
      <a-layout-header
        style="background: #fff; padding: 0; border-bottom: 1px solid #eee"
      >
        <a-row type="flex" justify="space-between" align="top" v-if="isMobile">
          <drawer-menu></drawer-menu>
          <router-link to="/">
            <div class="logo" />
          </router-link>
          <div></div>
        </a-row>
      </a-layout-header>
      <div class="office-breadcrumb" style="border-style: none">
        <slot name="breadcrumb"></slot>
      </div>
      <a-layout-content
        :style="{
          background: '#fff',
          padding: '24px',
          margin: 0,
        }"
      >
        <slot></slot>
      </a-layout-content>
    </a-layout>
  </a-layout>
</template>
<script>
import { computed, onMounted, watch, ref } from "vue";
import { useStore } from "vuex";
import DrawerMenu from "@/components/DrawerMenu";
import SideMenu from "@/components/SideMenu";
import { useRouter } from "vue-router";
export default {
  components: {
    "side-menu": SideMenu,
    "drawer-menu": DrawerMenu,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const isMobile = ref(false);
    const loaded = ref(false);

    onMounted(async () => {
      await store.dispatch("auth/profile");
      loaded.value = true;
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        isMobile.value = true;
      } else {
        isMobile.value = false;
      }
    });

    watch(
      () => store.getters["auth/currentUser"],
      (val) => {
        if (!val || !val.profile) {
          setTimeout(() => {
            router.push({ name: "login" });
          }, 200);
        } else if (!val.profile.hotelId) {
          if (val.role !== "ROOT") {
            setTimeout(() => {
              router.push({ name: "fillup" });
            }, 200);
          }
        }
      }
    );
    return {
      isMobile,
      collapsed: true,
      currentUser: computed(() => store.getters[`auth/currentUser`]),
      loaded,
    };
  },
};
</script>
<style>
#office-layout {
  margin: 0 auto;
}
#office-layout .trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

#office-layout .trigger:hover {
  color: #1890ff;
}

.office-breadcrumb {
  padding: 15px;
  background-color: #fff;
  border-bottom: 1px solid #efefef;
}

.ant-drawer-body {
  padding: 0;
}

.anticon {
  margin-right: 5px;
}

#office-layout .logo {
  width: 120px;
  padding-top: 31.6%;
  margin: 16px 24px 16px 24px;
  background: url("../assets/evenmore-logo.jpg") no-repeat;
  background-size: 100% auto;
}
#office-layout .logo-wrapper {
  padding: 0 15px;
  background: #fff;
}
</style>
